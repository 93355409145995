.layout-footer__inner{
  @include span(22 of 24);
  margin:auto;
  float:none;
  overflow:auto;
  @include rem-attribute('font-size', 0.75);
  border-top:1px solid $grey-light;
  @include rem-attribute('padding-top',1);
  @include rem-attribute('padding-bottom',1);

  //Prevent container becoming shorter than the content container
  @include breakpoint ($breakpoint-small){
    min-width:$container-small;
  }
  @include breakpoint ($breakpoint-medium){
    min-width:$container-medium;
  }
  @include breakpoint ($breakpoint-large){
    min-width:$container-large;
  }
  .block--menu-block{
    .menu{
      font-family:blank;
      .leaf{
        @include rem-attribute('font-size', 0.75);
        list-style:none;
        font-family:$sans;
      }
    }
  }
}

.footer-top{
  overflow:auto;
}

.footer-bottom{
  @include span(100%);
  .l-region{
    @include span(100%);
  }
}

.footer-base{
  @include rem-attribute('margin-top',1);
  @include span(100%);
  .copyright,
  .contact,
  .signature{
    color:$grey;
    a:link, a:active, a:visited{
      color:$grey;
    }
    a:hover{
      color:$grey-dark;
    }
  }
}
@include breakpoint ($breakpoint-medium){
  .layout-footer__inner{
    .block--menu-block{
      .menu{
        text-align:center;
        .leaf{
          display:inline-block;
          a{
            @include rem-attribute('padding',0.5);
          }
        }
      }
    }
  }

  .footer-top {
    .l-region--footer-first{
      @include span(isolate 4 at 1);
       //@include push(0);
    }
    .l-region--footer-second{
       @include span(isolate 4 at 5);
       //@include push(4);
     }
    .l-region--footer-third{
      @include span(isolate 4 at 9);
       //@include push(8);
    }
  }

  .footer-base{
    overflow:auto;
    position:relative;
    .copyright{
      float:left;
    }
    .contact{
      position: absolute;
      left: 50%;
      transform: translateX(-50%)
    }
    .signature{
      float:right;
      a:hover{
        text-decoration:none;
      }
    }
  }
}
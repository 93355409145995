.page-403{
  .content-header .content-header__content{
    position:relative;
  }
  .page-title{
    text-align:center;
    margin:auto;
    @include rem-attribute('margin-top',5);
    overflow:visible;
    float:none;
    .typed-cursor{
      display:inline-block;
      font-weight:300;
      color:$light;
      width:1px;
    }
    .page-title__title{
      display:inline-block;
      @include rem-attribute('font-size', 14);
      padding-right:0;
      padding-bottom:0;
      color:$light;
      background-color:transparent;
      text-align:center;
      font-weight:400;
      line-height:1.5;
      .page-title__title-typed-wrapper{
        white-space:pre;
      }
      .page-title__title-typed{
        white-space:pre;
      }
      .typed-cursor{
        @include rem-attribute('font-size', 14);
        line-height:1.6;
      }
    }
    .page-title__subtitle{
      @include rem-attribute('width', 26);
      max-width:100%;
      @include rem-attribute('min-height', 7);
      @include rem-attribute('font-size', 1.5);
      margin:auto;
      p, em{
        @include rem-attribute('font-size', 1.5);
      }
      p.sidenote{
        font-weight:300;
      }
      .typed-cursor{
        visibility:hidden;
      }
    }
  }
  .layout-content{
    @include rem-attribute('min-height', 6);
  }
}

.js{
  .page-403{
    .content-header{
      .page-title{
        overflow:hidden;
        .page-title__title-untyped,
        .page-title__subtitle-untyped{
          display:none;
        }
      }
    }
  }
}
$susy:(
  columns: 12,
  gutters: 1/4,
  math: fluid,
  output: float,
);

@include breakpoint-set('to ems', true);

$breakpoint-small:46em;
$breakpoint-medium:61em;
$breakpoint-large:76em;

$container-mobile:100%;
$container-small:720px; //720px- 45rem
$container-medium:960px; //960px - 60rem
$container-large:1200px;

$min-width:0px;

body{
  min-width: $min-width;
  @include container($container-mobile);
}

// Separate main sections
.layout-header,
.layout-main,
.layout-footer{
  clear:both;
  position:relative;
}

// Elastic width
.layout-header,
.layout-header .l-region,
.content-header,
.layout-footer,
.layout-footer .l-region{
  @include container(100%);
  @include span(100%);
}

// Small fixed width
@include breakpoint($breakpoint-small){
  .row,
  .content-header .page-title,
  .content-subheader .l-region--content-subheader,
  .layout-content,
  .layout-tab,
  {
    @include container($container-small);
  }
}

// Medium fixed width
@include breakpoint($breakpoint-medium){
  .row,
  .content-header .page-title,
  .content-subheader .l-region--content-subheader,
  .layout-content,
  .layout-tab,
  {
    @include container($container-medium);
  }
}

// Large fixed width
@include breakpoint($breakpoint-large){
  .row,
  .content-header .page-title,
  .content-subheader .l-region--content-subheader,
  .layout-content,
  .layout-tab,
  {
    @include container($container-large);
  }
}

.grids{
  .row{
    clear:both;
  }
}

.row{
  .grid{
    min-height:50px;
    background-color:$grey-dark;
    @include rem-attribute('margin-bottom',1);
  }
  .full{
    @include span(12 of 12);
  }
  .half{
    @include span(6 of 12);
  }
  .thirds{
    @include span(4 of 12);
  }
  .fourths{
    @include span(3 of 12);
  }
  .fifths{
    @include span(2.4 of 12);
  }
  .sixths{
    @include span(2 of 12);
  }
  .sevenths{
    @include span(1.714 of 12);
  }
  .eighths{
    @include span(1.5 of 12);
  }
  .nineths{
    @include span(1.333 of 12);
  }
  .tenths{
    @include span(1.2 of 12);
  }
  .elevenths{
    @include span(1.091 of 12);
  }
  .twelveths{
    @include span(1 of 12);
  }
  .span-1{
    @include span(1 of 12);
  }
  .span-2{
    @include span(2 of 12);
  }
  .span-3{
    @include span(3 of 12);
  }
  .span-4{
    @include span(4 of 12);
  }
  .span-5{
    @include span(5 of 12);
  }
  .span-6{
    @include span(6 of 12);
  }
  .span-7{
    @include span(7 of 12);
  }
  .span-8{
    @include span(8 of 12);
  }
  .span-9{
    @include span(9 of 12);
  }
  .span-10{
    @include span(10 of 12);
  }
  .span-11{
    @include span(11 of 12);
  }
  .last{
    @include last;
  }
}


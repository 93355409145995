.hljs.shell{
  color:$green;

  .hljs-number,
  .hljs-built_in,
  .hljs-variable,
  .hljs-id,
  .hljs-tag,
  .hljs-comment,
  .hljs-keyword,
  .hljs-string,
  .hljs-title,
  .hljs-symbol{
    color:$green;
    font-weight:400;
    font-style:normal;
  }
}
::selection{
  color:$light;
  background:$colour-primary;
}

.swatches{
  overflow:auto;
  .swatch{
    @include span(4 of 12);
    @include rem-attribute('margin-top',1);
    @include rem-attribute('margin-bottom',1);
    @include rem-attribute('padding-top',1.5);
    @include rem-attribute('padding-bottom',1.5);
    text-align:center;
  }
  // Every third element should have the margin-right removed
  .swatch:nth-child(3n){
    @include last;
  }
  .swatch:last-child{
    margin-bottom:0;
  }
}

.colour-primary{
  background-color:$colour-primary;
}

.colour-primary-dark{
  background-color:$colour-primary-dark;
}

.colour-secondary{
  background-color:$colour-secondary;
}

.dark{
  background-color:$dark;
  color:$light;
}

.light{
  background-color:$light;
}

.grey{
  background-color:$grey;
}

.grey-dark{
  background-color:$grey-dark;
  color:$light;
}

.grey-darker{
  background-color:$grey-darker;
  color:$light;
}

.grey-light{
  background-color:$grey-light;
}

.grey-lighter{
  background-color:$grey-lighter;
}

.grey-lightest{
  background-color:$grey-lightest;
}

.green{
  background-color:$green;
}

.green-dark{
  background-color:$green-dark;
}

.link-primary{
  background-color:$link-primary;
}

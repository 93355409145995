.layout-content{
  @include span(22 of 24);
  @include push(1 of 24);
  clear:both;
  overflow:auto;

  @include rem-attribute('min-height', 24);
}

@include breakpoint($breakpoint-small){
  .layout-content{
    margin-left:auto;
    margin-right:auto;
    float:none;
  }
}

@include breakpoint($breakpoint-medium){
  .sidebar{
    .layout-main-content{
      @include span(9 at 1);
      margin-left:0;
    }
  }
}
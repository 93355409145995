.layout-branding{
  float:left;
  .site-name{
    font-family:$serif;
    margin-top:0;
    margin-bottom:0;
    @include rem-attribute('font-size',1.5);
    a{
      transition:color 1s linear, transform 1s ease;

      color:$grey-dark;
      text-decoration:none;
      font-weight:$normalweight;
      span + span{
        font-weight:$lightweight;
      }
      &:hover{
        transform: rotateY(360deg);
        transform-style: preserve-3d;

        font-weight:$lightweight;
        color:$dark;
        span + span{
          font-weight:$normalweight;
        }
      }
    }
  }
  .site-slogan{
    display:inline-block;
    @include rem-attribute('font-size',0.875);
    font-weight:$lightweight;
    text-transform:none;
    margin:0;
    em{
      @include rem-attribute('font-size',0.875);
      font-weight:$lightweight;
    }
  }
}

.js{
  .layout-branding{
    .site-slogan__untyped{
      display:none;
    }
  }
}
img{
    height:auto;
    max-width:100%;
}

.image-bordered{
  @include rem-attribute('padding', 0.25);
  border:1px solid $grey;
  background-color:$light;
  box-sizing: border-box;
}
.image-rounded{
  @include rem-attribute('border-radius', 0.3);
}
.image-circular{
  border-radius:50%;
}

// In the main node content area
.node{
  img{
     @include rem-attribute('margin-bottom',1);
  }

  figure{
    @include rem-attribute('margin-bottom',1);
    img{
      margin-bottom:0;
      display:block;
    }
  }

  @include breakpoint($breakpoint-small){
    // each img will span 3 of 12 columns,
    // with 4 images in each row:
    .gallery figure,
    .gallery > img{
      @include gallery(3 of 12);
    }
  }
}
.pager{
  @include rem-attribute('margin-top', 1);
  .pager-items{
    float:left;
  }
  .pager-navigation{
    float:right;
  }
  .pager__item{
    display:block;
    float:left;
    padding:0;
    border-right:1px solid $colour-primary-dark;
    font:$font-paragraph;
    @include rem-attribute('font-size', 0.75);
    font-weight:300;
    @include button-bg($colour-primary);
    a, span{
      display:block;
      @include rem-attribute('padding-top', 0.25);
      @include rem-attribute('padding-right', 1);
      @include rem-attribute('padding-bottom', 0.25);
      @include rem-attribute('padding-left', 1);
    }
    a{
      color:$light;
      &:hover{
        text-decoration:none;
      }
    }
    &:first-child{
      border-radius:1px 0 0 1px;
    }
    &:last-child{
      border-radius:0 1px 1px 0;
      border-right:none;
    }
    &.pager__item--current,
    &.pager__item--ellipsis{
      background-color:$grey-lighter;
    }
    /* &.pager__item--ellipsis{
      background-color:none;
    } */
  }
}
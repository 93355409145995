.layout-aside-content{
  .block:first-child{
    @include rem-attribute('padding-top', 1);
    border-top:1px solid $grey-light;
  }
}

@include breakpoint($breakpoint-medium){
 .layout-aside-content{
    @include span(3 at 10);
    @include push(0);
    .block:first-child{
      border-top:none;
    }
  }
}
.node{
  ul{
    li{
      list-style-type: disc;
      margin-left:1.35em;
    }
  }
  .no-bullet, .no-bullets{
    li{
      list-style-type:none;
      margin-left:0;
    }
  }

  .vertical{
    li{
      display:block;
    }
  }

  .vertical.lined{
    li{
      padding:0;
      @include rem-attribute('padding-top',1);
      @include rem-attribute('padding-bottom',1);
      border-top:1px dotted $dark;
    }
  }
}

ol{
  li{
    list-style-type: decimal;
    margin-left:1.35em;
  }
}


table{
  border:1px solid #000;

  th, td{
  border:1px solid #000;
  padding:3px;
  }
  th{
    font-weight:$boldweight;
  }
}

body{
  font: $font-paragraph;
  color: $dark;
}

h1, h2, h3, h4, h5, h6{
  font:$font-paragraph-light;
  @include rem-attribute('margin-top', 1);
  @include rem-attribute('margin-bottom', 1);
}

h1{
  @include rem-attribute(font-size, 1.5);
}

.page-title h1{
  font:$font-heading;
}

h2{
  @include rem-attribute(font-size, 1.125);
  text-transform:uppercase;
}

h3{
  @include rem-attribute(font-size, 1);
  font-weight:$boldweight;
}

h4{
  @include rem-attribute(font-size, 1);
  text-transform:uppercase;
  font-style:italic;
}

h5{
  @include rem-attribute(font-size, 1);
  font-style:italic;
}

h6{
  @include rem-attribute(font-size, 1);
  font-weight:$thinweight;
}

.serif{
  font-family:$serif;
}

.sans{
  font-family:$sans;
}

.mono{
  font-family:$mono;
}

p{
  @include rem-attribute('margin-top', 1);
  @include rem-attribute('margin-bottom', 1);
}

p, li, dt, dd{
  font: $font-paragraph;
}

.lede{
  @include rem-attribute('font-size', 1.25);
  font-weight:$lightweight;
}

strong, b{
  font-weight:$boldweight;
}

em{
  font:$font-paragraph-italic;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub{
  vertical-align:sub;
}

sup{
  vertical-align:super;
}

abbr{
  border-bottom:1px dotted $dark;
}

kbd{
  font-family:$mono;
  @include rem-attribute('font-size',0.75);
}

q {
  quotes: "“" "”" "‘" "’";
}
q:before {
    content: open-quote;
}
q:after {
    content: close-quote;
}

blockquote{
  //@include rem-attribute('margin-left', 1);
  @include rem-attribute('margin-top', 1);
  @include rem-attribute('margin-bottom', 1);
  @include rem-attribute('padding-left', 1);
  border-left:5px solid #000;
  font:$font-paragraph-italic;
  p{
    font:$font-paragraph-italic;
    display:inline;
    padding-top:0;
    padding-bottom:0;
  }
}

blockquote:before {
  content: "\201C";
  @include rem-attribute('font-size',1.5);
}

figcaption,
cite,
.source,
.caption{
  font:$font-paragraph-italic;
  @include rem-attribute('font-size', 0.75);
  //@include rem-attribute('margin-top', -1);
}

.caption{
  margin-top:0;
}

.source:before,
cite:before{
  content:'- ';
}

.quote{
  //@include rem-attribute('margin-left', 1);
  @include rem-attribute('padding-left', 1);
  border-left:5px solid #000;
  blockquote{
    margin-left:0;
    padding-left:0;
    border-left:none;
  }
}

dfn, var{
  font:$font-paragraph-italic;
}

mark{
  background-color:$grey-lighter;
}

.text-info{
  color:$colour-info;
}

.text-error{
  color:$colour-error;
}

.text-success{
  color:$colour-success;
}

.text-left{
  text-align:left;
}

.text-center{
  text-align:center;
}

.text-right{
  text-align:right;
}

.text-justify{
  text-align:justify;
}

dd{
  @include rem-attribute('margin-left',1.5);
}
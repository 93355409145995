.maintenance-page.page-error{

  .layout-header{
    position:relative;
    border:none;
    padding:0;
    .site-name{
      img{
        display:block;
        margin:0 auto;
        max-width:100%;
      }
    }
  }

  .content-header{
    min-height:0;
    .content-header__content{
      position:relative;
    }
  }

  .page-title{
    text-align:center;
    margin:auto;
    overflow:visible;
    float:none;

    .page-title__title{
      display:inline-block;
      @include rem-attribute('font-size', 4);
      padding-right:0;
      padding-bottom:0;
      color:$light;
      background-color:transparent;
      text-align:center;
      font-weight:400;
      line-height:1.2;
      min-height:0;
    }
    .page-title__subtitle{
      @include rem-attribute('margin-top', 2);
      margin-left:auto;
      margin-right:auto;
      @include rem-attribute('width', 45);
      max-width:100%;
      @include rem-attribute('font-size', 1.5);
      p, em{
        @include rem-attribute('font-size', 1.5);
      }
      p.sidenote{
        font-weight:300;
      }
    }
  }

  @include breakpoint($breakpoint-small){
    .page-title{
      .page-title__title{
        @include rem-attribute('font-size', 6);
      }
      .page-title__subtitle{
        @include rem-attribute('margin-top', 5);
      }
    }
  }
}

.maintenance-page.page-error.glitch{
  .layout-header{
    background-color:#1b1b1b;
  }
}

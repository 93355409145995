.block--views-blog-listing-recent{
  .teaser-listing{
    .teaser-footer h2{
       @include span(12);
    }
    .teaser-footer .snippet{
       @include span(12);
       @include rem-attribute('margin-top',1);
    }
  }
  @include breakpoint($breakpoint-small){
    .views-row{
      .teaser-listing{
        @include span(6);
      }
      &:last-child{
        .teaser-listing{
          margin:0;
        }
      }
    }
  }
}
.hljs{
  font:$font-mono;

  .hljs-comment{
    font-style: italic;
  }

  .hljs-preprocessor{
    color:$light;
  }

  .hljs-keyword,
  .hljs-id,
  .hljs-tag{
    font-weight:$normalweight;
  }
}

.hljs.coffeescript .javascript,
.hljs.javascript .xml,
.hljs.tex .hljs-formula,
.hljs.xml .javascript,
.hljs.xml .vbscript,
.hljs.xml .css,
.hljs.xml .hljs-cdata,
.hljs.xml .php, .php .xml{
  opacity:1;
}
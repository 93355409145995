.page-sandbox{
  .view-sandbox-listing{
    &.view-display-id-page_1 li{
      @include rem-attribute('margin-bottom',1);
      font:$font-heading;
      @include rem-attribute('font-size', 1.5);
      a{
        font:$font-heading;
        @include rem-attribute('font-size', 1.5);
        @include rem-attribute('margin-right',0.25);
      }
    }
    &.view-display-id-page_1 .view-content,
    &.view-display-id-page_2 .view-content,
    &.view-display-id-page_blog_complete .view-content{
      @include rem-attribute('min-height', 30);
    }
    .views-row{
      @include rem-attribute('padding-bottom', 1);
      @include rem-attribute('margin-bottom', 1);
      .views-field-created{
        color:$grey;
        @include rem-attribute('font-size', 0.75);
      }
      .views-field-title{
        h2{
          @include rem-attribute('margin-bottom', 0.75);
          margin-top:0;
          font-family:$serif;
          font-weight:$lightweight;
        }
      }
      .views-field-body{
        p:first-child{
          margin-top:0;
        }
        p:last-child{
          margin-bottom:0;
        }
      }
    }
  }
}
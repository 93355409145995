$background-color:#272822;
$foreground-color:#f92672; //#f8f8f2; //#f92672; //#a6e22e;
$keyword:#66d9ef;
$keyword2:#ae81ff;
$keyword3:#f92672;
$keyword5:#f92672;
$comment1:#75715e;
$comment2:#75715e;
$commentmulti:#75715e;
$brackets:#f8f8f2;
$symbol:#f8f8f2;
$string:#e6db74;
$number:#ae81ff;
$property:#f8f8f2;

.node{
  div.geshifilter{

  background-color:$background-color;
  color:$foreground-color;
  font-family:monospace;

  //default styles

    .de1, .de2 {font-family: monospace; font-weight: normal; font-style: normal}
    .imp {font-weight: bold; color: red;}
    li,  .li1 {font-family: monospace; font-weight: normal;}
    .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
    .li2 {font-family: monospace; font-weight: normal; font-style: italic;}
    .kw1 {color: $keyword; font-weight: normal;}
    .kw2 {color: $keyword2; font-weight: normal;}
    .kw3 {color: $keyword3;}
    .kw5 {color: $keyword5;}
    .co1 {color: $comment1; font-style: italic;}
    .co2 {color: $comment2; font-style: italic;}
    .coMULTI {color: $commentmulti; font-style: italic;}
    .es0 {color: #000099; font-weight: normal;}
    .br0 {color: $brackets;}
    .sy0 {color: $symbol;}
    .st0 {color: $string;}
    .nu0 {color: $number;}
    .me1 {color: $property;}
    .ln-xtra,  li.ln-xtra,  div.ln-xtra {background-color: #ffc;}
    span.xtra { display:block; }
  }
}

/**
 * GeSHi Dynamically Generated Stylesheet
 * --------------------------------------
 * Dynamically generated stylesheet for awk
 * CSS class: geshifilter-awk, CSS id:
 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
 * (http://qbnz.com/highlighter/ and http://geshi.org/)
 * --------------------------------------
 */
.awk.geshifilter-awk .de1, .awk.geshifilter-awk .de2 {font-family: monospace; font-weight: normal; font-style: normal}
.awk.geshifilter-awk  {font-family:monospace;}
.awk.geshifilter-awk .imp {font-weight: bold; color: red;}
.awk.geshifilter-awk li, .awk.geshifilter-awk .li1 {font-family: monospace; font-weight: normal;}
.awk.geshifilter-awk .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
.awk.geshifilter-awk .li2 {font-family: monospace; font-weight: bold; font-style: italic;}
.awk.geshifilter-awk .kw1 {color: #000000; font-weight: bold;}
.awk.geshifilter-awk .kw2 {color: #C20CB9; font-weight: bold;}
.awk.geshifilter-awk .kw3 {color: #4107D5; font-weight: bold;}
.awk.geshifilter-awk .kw4 {color: #07D589; font-weight: bold;}
.awk.geshifilter-awk .kw5 {color: #0BD507; font-weight: bold;}
.awk.geshifilter-awk .kw6 {color: #078CD5; font-weight: bold;}
.awk.geshifilter-awk .co1 {color:#808080;}
.awk.geshifilter-awk .es0 {color: #000099; font-weight: bold;}
.awk.geshifilter-awk .br0 {color: #7a0874; font-weight: bold;}
.awk.geshifilter-awk .sy0 {color:black;}
.awk.geshifilter-awk .sy1 {color:black;}
.awk.geshifilter-awk .sy2 {color:black;}
.awk.geshifilter-awk .sy3 {color:black;}
.awk.geshifilter-awk .sy4 {color:#C4C364;}
.awk.geshifilter-awk .sy5 {color:black;font-weight:bold;}
.awk.geshifilter-awk .st0 {color: #ff0000;}
.awk.geshifilter-awk .nu0 {color: #000000;}
.awk.geshifilter-awk .re0 {color:#000088;}
.awk.geshifilter-awk .ln-xtra, .awk.geshifilter-awk li.ln-xtra, .awk.geshifilter-awk div.ln-xtra {background-color: #ffc;}
.awk.geshifilter-awk span.xtra { display:block; }

/**
 * GeSHi Dynamically Generated Stylesheet
 * --------------------------------------
 * Dynamically generated stylesheet for bash
 * CSS class: geshifilter-bash, CSS id:
 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
 * (http://qbnz.com/highlighter/ and http://geshi.org/)
 * --------------------------------------
 */
.bash.geshifilter-bash .de1, .bash.geshifilter-bash .de2 {font-family: monospace; font-weight: normal; font-style: normal}
.bash.geshifilter-bash  {font-family:monospace;}
.bash.geshifilter-bash .imp {font-weight: bold; color: red;}
.bash.geshifilter-bash li, .bash.geshifilter-bash .li1 {font-family: monospace; font-weight: normal;}
.bash.geshifilter-bash .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
.bash.geshifilter-bash .li2 {font-family: monospace; font-weight: bold; font-style: italic;}
.bash.geshifilter-bash .kw1 {color: #000000; font-weight: bold;}
.bash.geshifilter-bash .kw2 {color: #c20cb9; font-weight: bold;}
.bash.geshifilter-bash .kw3 {color: #7a0874; font-weight: bold;}
.bash.geshifilter-bash .co0 {color: #666666; font-style: italic;}
.bash.geshifilter-bash .co1 {color: #800000;}
.bash.geshifilter-bash .co2 {color: #cc0000; font-style: italic;}
.bash.geshifilter-bash .co3 {color: #000000; font-weight: bold;}
.bash.geshifilter-bash .co4 {color: #666666;}
.bash.geshifilter-bash .es1 {color: #000099; font-weight: bold;}
.bash.geshifilter-bash .es2 {color: #007800;}
.bash.geshifilter-bash .es3 {color: #007800;}
.bash.geshifilter-bash .es4 {color: #007800;}
.bash.geshifilter-bash .es5 {color: #780078;}
.bash.geshifilter-bash .es_h {color: #000099; font-weight: bold;}
.bash.geshifilter-bash .br0 {color: #7a0874; font-weight: bold;}
.bash.geshifilter-bash .sy0 {color: #000000; font-weight: bold;}
.bash.geshifilter-bash .st0 {color: #ff0000;}
.bash.geshifilter-bash .st_h {color: #ff0000;}
.bash.geshifilter-bash .nu0 {color: #000000;}
.bash.geshifilter-bash .re0 {color: #007800;}
.bash.geshifilter-bash .re1 {color: #007800;}
.bash.geshifilter-bash .re2 {color: #007800;}
.bash.geshifilter-bash .re4 {color: #007800;}
.bash.geshifilter-bash .re5 {color: #660033;}
.bash.geshifilter-bash .ln-xtra, .bash.geshifilter-bash li.ln-xtra, .bash.geshifilter-bash div.ln-xtra {background-color: #ffc;}
.bash.geshifilter-bash span.xtra { display:block; }

/**
 * GeSHi Dynamically Generated Stylesheet
 * --------------------------------------
 * Dynamically generated stylesheet for c
 * CSS class: geshifilter-c, CSS id:
 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
 * (http://qbnz.com/highlighter/ and http://geshi.org/)
 * --------------------------------------
 */
.c.geshifilter-c .de1, .c.geshifilter-c .de2 {font-family: monospace; font-weight: normal; font-style: normal}
.c.geshifilter-c  {font-family:monospace;}
.c.geshifilter-c .imp {font-weight: bold; color: red;}
.c.geshifilter-c li, .c.geshifilter-c .li1 {font-family: monospace; font-weight: normal;}
.c.geshifilter-c .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
.c.geshifilter-c .li2 {font-family: monospace; font-weight: bold; font-style: italic;}
.c.geshifilter-c .kw1 {color: #b1b100;}
.c.geshifilter-c .kw2 {color: #000000; font-weight: bold;}
.c.geshifilter-c .kw3 {color: #000066;}
.c.geshifilter-c .kw4 {color: #993333;}
.c.geshifilter-c .co1 {color: #666666; font-style: italic;}
.c.geshifilter-c .co2 {color: #339933;}
.c.geshifilter-c .coMULTI {color: #808080; font-style: italic;}
.c.geshifilter-c .es0 {color: #000099; font-weight: bold;}
.c.geshifilter-c .es1 {color: #000099; font-weight: bold;}
.c.geshifilter-c .es2 {color: #660099; font-weight: bold;}
.c.geshifilter-c .es3 {color: #660099; font-weight: bold;}
.c.geshifilter-c .es4 {color: #660099; font-weight: bold;}
.c.geshifilter-c .es5 {color: #006699; font-weight: bold;}
.c.geshifilter-c .br0 {color: #009900;}
.c.geshifilter-c .sy0 {color: #339933;}
.c.geshifilter-c .st0 {color: #ff0000;}
.c.geshifilter-c .nu0 {color: #0000dd;}
.c.geshifilter-c .nu6 {color: #208080;}
.c.geshifilter-c .nu8 {color: #208080;}
.c.geshifilter-c .nu12 {color: #208080;}
.c.geshifilter-c .nu16 {color:#800080;}
.c.geshifilter-c .nu17 {color:#800080;}
.c.geshifilter-c .nu18 {color:#800080;}
.c.geshifilter-c .nu19 {color:#800080;}
.c.geshifilter-c .me1 {color: #202020;}
.c.geshifilter-c .me2 {color: #202020;}
.c.geshifilter-c .ln-xtra, .c.geshifilter-c li.ln-xtra, .c.geshifilter-c div.ln-xtra {background-color: #ffc;}
.c.geshifilter-c span.xtra { display:block; }

/**
 * GeSHi Dynamically Generated Stylesheet
 * --------------------------------------
 * Dynamically generated stylesheet for coffeescript
 * CSS class: geshifilter-coffeescript, CSS id:
 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
 * (http://qbnz.com/highlighter/ and http://geshi.org/)
 * --------------------------------------
 */
.coffeescript.geshifilter-coffeescript .de1, .coffeescript.geshifilter-coffeescript .de2 {font-family: monospace; font-weight: normal; font-style: normal}
.coffeescript.geshifilter-coffeescript  {font-family:monospace;}
.coffeescript.geshifilter-coffeescript .imp {font-weight: bold; color: red;}
.coffeescript.geshifilter-coffeescript li, .coffeescript.geshifilter-coffeescript .li1 {font-family: monospace; font-weight: normal;}
.coffeescript.geshifilter-coffeescript .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
.coffeescript.geshifilter-coffeescript .li2 {font-family: monospace; font-weight: bold; font-style: italic;}
.coffeescript.geshifilter-coffeescript .kw1 {color: #ff7700;font-weight:bold;}
.coffeescript.geshifilter-coffeescript .kw2 {color: #008000;}
.coffeescript.geshifilter-coffeescript .kw3 {color: #dc143c;}
.coffeescript.geshifilter-coffeescript .kw4 {color: #0000cd;}
.coffeescript.geshifilter-coffeescript .co1 {color: #808080; font-style: italic;}
.coffeescript.geshifilter-coffeescript .coMULTI {color: #808080; font-style: italic;}
.coffeescript.geshifilter-coffeescript .es0 {color: #000099; font-weight: bold;}
.coffeescript.geshifilter-coffeescript .br0 {color: black;}
.coffeescript.geshifilter-coffeescript .sy0 {color: #66cc66;}
.coffeescript.geshifilter-coffeescript .st0 {color: #483d8b;}
.coffeescript.geshifilter-coffeescript .nu0 {color: #ff4500;}
.coffeescript.geshifilter-coffeescript .me1 {color: black;}
.coffeescript.geshifilter-coffeescript .ln-xtra, .coffeescript.geshifilter-coffeescript li.ln-xtra, .coffeescript.geshifilter-coffeescript div.ln-xtra {background-color: #ffc;}
.coffeescript.geshifilter-coffeescript span.xtra { display:block; }

/**
 * GeSHi Dynamically Generated Stylesheet
 * --------------------------------------
 * Dynamically generated stylesheet for cpp
 * CSS class: geshifilter-cpp, CSS id:
 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
 * (http://qbnz.com/highlighter/ and http://geshi.org/)
 * --------------------------------------
 */
.cpp.geshifilter-cpp .de1, .cpp.geshifilter-cpp .de2 {font-family: monospace; font-weight: normal; font-style: normal}
.cpp.geshifilter-cpp  {font-family:monospace;}
.cpp.geshifilter-cpp .imp {font-weight: bold; color: red;}
.cpp.geshifilter-cpp li, .cpp.geshifilter-cpp .li1 {font-family: monospace; font-weight: normal;}
.cpp.geshifilter-cpp .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
.cpp.geshifilter-cpp .li2 {font-family: monospace; font-weight: bold; font-style: italic;}
.cpp.geshifilter-cpp .kw1 {color: #0000ff;}
.cpp.geshifilter-cpp .kw2 {color: #0000ff;}
.cpp.geshifilter-cpp .kw3 {color: #0000dd;}
.cpp.geshifilter-cpp .kw4 {color: #0000ff;}
.cpp.geshifilter-cpp .co1 {color: #666666;}
.cpp.geshifilter-cpp .co2 {color: #339900;}
.cpp.geshifilter-cpp .coMULTI {color: #ff0000; font-style: italic;}
.cpp.geshifilter-cpp .es0 {color: #000099; font-weight: bold;}
.cpp.geshifilter-cpp .es1 {color: #000099; font-weight: bold;}
.cpp.geshifilter-cpp .es2 {color: #660099; font-weight: bold;}
.cpp.geshifilter-cpp .es3 {color: #660099; font-weight: bold;}
.cpp.geshifilter-cpp .es4 {color: #660099; font-weight: bold;}
.cpp.geshifilter-cpp .es5 {color: #006699; font-weight: bold;}
.cpp.geshifilter-cpp .br0 {color: #008000;}
.cpp.geshifilter-cpp .sy0 {color: #008000;}
.cpp.geshifilter-cpp .sy1 {color: #000080;}
.cpp.geshifilter-cpp .sy2 {color: #000040;}
.cpp.geshifilter-cpp .sy3 {color: #000040;}
.cpp.geshifilter-cpp .sy4 {color: #008080;}
.cpp.geshifilter-cpp .st0 {color: #FF0000;}
.cpp.geshifilter-cpp .nu0 {color: #0000dd;}
.cpp.geshifilter-cpp .nu6 {color: #208080;}
.cpp.geshifilter-cpp .nu8 {color: #208080;}
.cpp.geshifilter-cpp .nu12 {color: #208080;}
.cpp.geshifilter-cpp .nu16 {color:#800080;}
.cpp.geshifilter-cpp .nu17 {color:#800080;}
.cpp.geshifilter-cpp .nu18 {color:#800080;}
.cpp.geshifilter-cpp .nu19 {color:#800080;}
.cpp.geshifilter-cpp .me1 {color: #007788;}
.cpp.geshifilter-cpp .me2 {color: #007788;}
.cpp.geshifilter-cpp .ln-xtra, .cpp.geshifilter-cpp li.ln-xtra, .cpp.geshifilter-cpp div.ln-xtra {background-color: #ffc;}
.cpp.geshifilter-cpp span.xtra { display:block; }

/**
 * GeSHi Dynamically Generated Stylesheet
 * --------------------------------------
 * Dynamically generated stylesheet for css
 * CSS class: geshifilter-css, CSS id:
 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
 * (http://qbnz.com/highlighter/ and http://geshi.org/)
 * --------------------------------------
 */
.css.geshifilter-css .de1, .css.geshifilter-css .de2 {font-family: monospace; font-weight: normal; font-style: normal}
.css.geshifilter-css  {font-family:monospace;}
.css.geshifilter-css .imp {font-weight: bold; color: red;}
.css.geshifilter-css li, .css.geshifilter-css .li1 {font-family: monospace; font-weight: normal;}
.css.geshifilter-css .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
.css.geshifilter-css .li2 {font-family: monospace; font-weight: bold; font-style: italic;}
.css.geshifilter-css .kw1 {color: #000000; font-weight: bold;}
.css.geshifilter-css .kw2 {color: #993333;}
.css.geshifilter-css .co1 {color: #a1a100;}
.css.geshifilter-css .co2 {color: #ff0000; font-style: italic;}
.css.geshifilter-css .coMULTI {color: #808080; font-style: italic;}
.css.geshifilter-css .es0 {color: #000099; font-weight: bold;}
.css.geshifilter-css .es2 {color: #000099; font-weight: bold;}
.css.geshifilter-css .br0 {color: #00AA00;}
.css.geshifilter-css .sy0 {color: #00AA00;}
.css.geshifilter-css .st0 {color: #ff0000;}
.css.geshifilter-css .nu0 {color: #cc66cc;}
.css.geshifilter-css .re0 {color: #cc00cc;}
.css.geshifilter-css .re1 {color: #6666ff;}
.css.geshifilter-css .re2 {color: #3333ff;}
.css.geshifilter-css .re3 {color: #933;}
.css.geshifilter-css .ln-xtra, .css.geshifilter-css li.ln-xtra, .css.geshifilter-css div.ln-xtra {background-color: #ffc;}
.css.geshifilter-css span.xtra { display:block; }

/**
 * GeSHi Dynamically Generated Stylesheet
 * --------------------------------------
 * Dynamically generated stylesheet for drupal6
 * CSS class: geshifilter-drupal6, CSS id:
 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
 * (http://qbnz.com/highlighter/ and http://geshi.org/)
 * --------------------------------------
 */
.drupal6.geshifilter-drupal6 .de1, .drupal6.geshifilter-drupal6 .de2 {font-family: monospace; font-weight: normal; font-style: normal}
.drupal6.geshifilter-drupal6  {font-family:monospace;}
.drupal6.geshifilter-drupal6 .imp {font-weight: bold; color: red;}
.drupal6.geshifilter-drupal6 li, .drupal6.geshifilter-drupal6 .li1 {font-family: monospace; font-weight: normal;}
.drupal6.geshifilter-drupal6 .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
.drupal6.geshifilter-drupal6 .li2 {font-family: monospace; font-weight: bold; font-style: italic;}
.drupal6.geshifilter-drupal6 .kw1 {color: #b1b100;}
.drupal6.geshifilter-drupal6 .kw2 {color: #000000; font-weight: bold;}
.drupal6.geshifilter-drupal6 .kw3 {color: #000066;}
.drupal6.geshifilter-drupal6 .kw5 {color: #000066;}
.drupal6.geshifilter-drupal6 .kw6 {color: #000000; font-weight: bold;}
.drupal6.geshifilter-drupal6 .co1 {color: #808080; font-style: italic;}
.drupal6.geshifilter-drupal6 .co2 {color: #808080; font-style: italic;}
.drupal6.geshifilter-drupal6 .coMULTI {color: #808080; font-style: italic;}
.drupal6.geshifilter-drupal6 .es0 {color: #000099; font-weight: bold;}
.drupal6.geshifilter-drupal6 .br0 {color: #66cc66;}
.drupal6.geshifilter-drupal6 .sy0 {color: #66cc66;}
.drupal6.geshifilter-drupal6 .st0 {color: #ff0000;}
.drupal6.geshifilter-drupal6 .nu0 {color: #cc66cc;}
.drupal6.geshifilter-drupal6 .me1 {color: #006600;}
.drupal6.geshifilter-drupal6 .me2 {color: #006600;}
.drupal6.geshifilter-drupal6 .re0 {color: #0000ff;}
.drupal6.geshifilter-drupal6 .re1 {color: #ff0000}
.drupal6.geshifilter-drupal6 .ln-xtra, .drupal6.geshifilter-drupal6 li.ln-xtra, .drupal6.geshifilter-drupal6 div.ln-xtra {background-color: #ffc;}
.drupal6.geshifilter-drupal6 span.xtra { display:block; }

/**
 * GeSHi Dynamically Generated Stylesheet
 * --------------------------------------
 * Dynamically generated stylesheet for html4strict
 * CSS class: geshifilter-html4strict, CSS id:
 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
 * (http://qbnz.com/highlighter/ and http://geshi.org/)
 * --------------------------------------
 */
.html4strict.geshifilter-html4strict .de1, .html4strict.geshifilter-html4strict .de2 {font-family: monospace; font-weight: normal; font-style: normal}
.html4strict.geshifilter-html4strict  {font-family:monospace;}
.html4strict.geshifilter-html4strict .imp {font-weight: bold; color: red;}
.html4strict.geshifilter-html4strict li, .html4strict.geshifilter-html4strict .li1 {font-family: monospace; font-weight: normal;}
.html4strict.geshifilter-html4strict .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
.html4strict.geshifilter-html4strict .li2 {font-family: monospace; font-weight: bold; font-style: italic;}
.html4strict.geshifilter-html4strict .kw2 {color: #000000; font-weight: bold;}
.html4strict.geshifilter-html4strict .kw3 {color: #000066;}
.html4strict.geshifilter-html4strict .es0 {color: #000099; font-weight: bold;}
.html4strict.geshifilter-html4strict .br0 {color: #66cc66;}
.html4strict.geshifilter-html4strict .sy0 {color: #66cc66;}
.html4strict.geshifilter-html4strict .st0 {color: #ff0000;}
.html4strict.geshifilter-html4strict .nu0 {color: #cc66cc;}
.html4strict.geshifilter-html4strict .sc-2 {color: #404040;}
.html4strict.geshifilter-html4strict .sc-1 {color: #808080; font-style: italic;}
.html4strict.geshifilter-html4strict .sc0 {color: #00bbdd;}
.html4strict.geshifilter-html4strict .sc1 {color: #ddbb00;}
.html4strict.geshifilter-html4strict .sc2 {color: #009900;}
.html4strict.geshifilter-html4strict .ln-xtra, .html4strict.geshifilter-html4strict li.ln-xtra, .html4strict.geshifilter-html4strict div.ln-xtra {background-color: #ffc;}
.html4strict.geshifilter-html4strict span.xtra { display:block; }

/**
 * GeSHi Dynamically Generated Stylesheet
 * --------------------------------------
 * Dynamically generated stylesheet for html5
 * CSS class: geshifilter-html5, CSS id:
 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
 * (http://qbnz.com/highlighter/ and http://geshi.org/)
 * --------------------------------------
 */
.html5.geshifilter-html5 .de1, .html5.geshifilter-html5 .de2 {font-family: monospace; font-weight: normal; font-style: normal}
.html5.geshifilter-html5  {font-family:monospace;}
.html5.geshifilter-html5 .imp {font-weight: bold; color: red;}
.html5.geshifilter-html5 li, .html5.geshifilter-html5 .li1 {font-family: monospace; font-weight: normal;}
.html5.geshifilter-html5 .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
.html5.geshifilter-html5 .li2 {font-family: monospace; font-weight: bold; font-style: italic;}
.html5.geshifilter-html5 .kw2 {color: #000000; font-weight: bold;}
.html5.geshifilter-html5 .kw3 {color: #000066;}
.html5.geshifilter-html5 .es0 {color: #000099; font-weight: bold;}
.html5.geshifilter-html5 .br0 {color: #66cc66;}
.html5.geshifilter-html5 .sy0 {color: #66cc66;}
.html5.geshifilter-html5 .st0 {color: #ff0000;}
.html5.geshifilter-html5 .nu0 {color: #cc66cc;}
.html5.geshifilter-html5 .sc-2 {color: #404040;}
.html5.geshifilter-html5 .sc-1 {color: #808080; font-style: italic;}
.html5.geshifilter-html5 .sc0 {color: #00bbdd;}
.html5.geshifilter-html5 .sc1 {color: #ddbb00;}
.html5.geshifilter-html5 .sc2 {color: #009900;}
.html5.geshifilter-html5 .ln-xtra, .html5.geshifilter-html5 li.ln-xtra, .html5.geshifilter-html5 div.ln-xtra {background-color: #ffc;}
.html5.geshifilter-html5 span.xtra { display:block; }

/**
 * GeSHi Dynamically Generated Stylesheet
 * --------------------------------------
 * Dynamically generated stylesheet for java
 * CSS class: geshifilter-java, CSS id:
 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
 * (http://qbnz.com/highlighter/ and http://geshi.org/)
 * --------------------------------------
 */
.java.geshifilter-java .de1, .java.geshifilter-java .de2 {font-family: monospace; font-weight: normal; font-style: normal}
.java.geshifilter-java  {font-family:monospace;}
.java.geshifilter-java .imp {font-weight: bold; color: red;}
.java.geshifilter-java li, .java.geshifilter-java .li1 {font-family: monospace; font-weight: normal;}
.java.geshifilter-java .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
.java.geshifilter-java .li2 {font-family: monospace; font-weight: bold; font-style: italic;}
.java.geshifilter-java .kw1 {color: #000000; font-weight: bold;}
.java.geshifilter-java .kw2 {color: #000066; font-weight: bold;}
.java.geshifilter-java .kw3 {color: #003399;}
.java.geshifilter-java .kw4 {color: #000066; font-weight: bold;}
.java.geshifilter-java .co1 {color: #666666; font-style: italic;}
.java.geshifilter-java .co2 {color: #006699;}
.java.geshifilter-java .co3 {color: #008000; font-style: italic; font-weight: bold;}
.java.geshifilter-java .coMULTI {color: #666666; font-style: italic;}
.java.geshifilter-java .es0 {color: #000099; font-weight: bold;}
.java.geshifilter-java .br0 {color: #009900;}
.java.geshifilter-java .sy0 {color: #339933;}
.java.geshifilter-java .st0 {color: #0000ff;}
.java.geshifilter-java .nu0 {color: #cc66cc;}
.java.geshifilter-java .me1 {color: #006633;}
.java.geshifilter-java .me2 {color: #006633;}
.java.geshifilter-java .ln-xtra, .java.geshifilter-java li.ln-xtra, .java.geshifilter-java div.ln-xtra {background-color: #ffc;}
.java.geshifilter-java span.xtra { display:block; }

/**
 * GeSHi Dynamically Generated Stylesheet
 * --------------------------------------
 * Dynamically generated stylesheet for javascript
 * CSS class: geshifilter-javascript, CSS id:
 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
 * (http://qbnz.com/highlighter/ and http://geshi.org/)
 * --------------------------------------
 */

.javascript.geshifilter-javascript .de1, .javascript.geshifilter-javascript .de2 {font-family: monospace; font-weight: normal; font-style: normal}
.javascript.geshifilter-javascript  {font-family:monospace;}
.javascript.geshifilter-javascript .imp {font-weight: bold; color: red;}
.javascript.geshifilter-javascript li, .javascript.geshifilter-javascript .li1 {font-family: monospace; font-weight: normal;}
.javascript.geshifilter-javascript .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
.javascript.geshifilter-javascript .li2 {font-family: monospace; font-weight: bold; font-style: italic;}
.javascript.geshifilter-javascript .kw1 {color: #000066; font-weight: bold;}
.javascript.geshifilter-javascript .kw2 {color: #003366; font-weight: bold;}
.javascript.geshifilter-javascript .kw3 {color: #000066;}
.javascript.geshifilter-javascript .kw5 {color: #FF0000;}
.javascript.geshifilter-javascript .co1 {color: #006600; font-style: italic;}
.javascript.geshifilter-javascript .co2 {color: #009966; font-style: italic;}
.javascript.geshifilter-javascript .coMULTI {color: #006600; font-style: italic;}
.javascript.geshifilter-javascript .es0 {color: #000099; font-weight: bold;}
.javascript.geshifilter-javascript .br0 {color: #009900;}
.javascript.geshifilter-javascript .sy0 {color: #339933;}
.javascript.geshifilter-javascript .st0 {color: #3366CC;}
.javascript.geshifilter-javascript .nu0 {color: #CC0000;}
.javascript.geshifilter-javascript .me1 {color: #660066;}
.javascript.geshifilter-javascript .ln-xtra, .javascript.geshifilter-javascript li.ln-xtra, .javascript.geshifilter-javascript div.ln-xtra {background-color: #ffc;}
.javascript.geshifilter-javascript span.xtra { display:block; }

/**
 * GeSHi Dynamically Generated Stylesheet
 * --------------------------------------
 * Dynamically generated stylesheet for mysql
 * CSS class: geshifilter-mysql, CSS id:
 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
 * (http://qbnz.com/highlighter/ and http://geshi.org/)
 * --------------------------------------
 */
.mysql.geshifilter-mysql .de1, .mysql.geshifilter-mysql .de2 {font-family: monospace; font-weight: normal; font-style: normal}
.mysql.geshifilter-mysql  {font-family:monospace;}
.mysql.geshifilter-mysql .imp {font-weight: bold; color: red;}
.mysql.geshifilter-mysql li, .mysql.geshifilter-mysql .li1 {font-family: monospace; font-weight: normal;}
.mysql.geshifilter-mysql .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
.mysql.geshifilter-mysql .li2 {font-family: monospace; font-weight: bold; font-style: italic;}
.mysql.geshifilter-mysql .kw1 {color: #990099; font-weight: bold;}
.mysql.geshifilter-mysql .kw2 {color: #990099; font-weight: bold;}
.mysql.geshifilter-mysql .kw3 {color: #9900FF; font-weight: bold;}
.mysql.geshifilter-mysql .kw4 {color: #999900; font-weight: bold;}
.mysql.geshifilter-mysql .kw5 {color: #999900; font-weight: bold;}
.mysql.geshifilter-mysql .kw6 {color: #FF9900; font-weight: bold;}
.mysql.geshifilter-mysql .kw7 {color: #FF9900; font-weight: bold;}
.mysql.geshifilter-mysql .kw8 {color: #9900FF; font-weight: bold;}
.mysql.geshifilter-mysql .kw9 {color: #9900FF; font-weight: bold;}
.mysql.geshifilter-mysql .kw10 {color: #CC0099; font-weight: bold;}
.mysql.geshifilter-mysql .kw11 {color: #CC0099; font-weight: bold;}
.mysql.geshifilter-mysql .kw12 {color: #009900;}
.mysql.geshifilter-mysql .kw13 {color: #000099;}
.mysql.geshifilter-mysql .kw14 {color: #000099;}
.mysql.geshifilter-mysql .kw15 {color: #000099;}
.mysql.geshifilter-mysql .kw16 {color: #000099;}
.mysql.geshifilter-mysql .kw17 {color: #000099;}
.mysql.geshifilter-mysql .kw18 {color: #000099;}
.mysql.geshifilter-mysql .kw19 {color: #000099;}
.mysql.geshifilter-mysql .kw20 {color: #000099;}
.mysql.geshifilter-mysql .kw21 {color: #000099;}
.mysql.geshifilter-mysql .kw22 {color: #000099;}
.mysql.geshifilter-mysql .kw23 {color: #000099;}
.mysql.geshifilter-mysql .kw24 {color: #000099;}
.mysql.geshifilter-mysql .kw25 {color: #000099;}
.mysql.geshifilter-mysql .kw26 {color: #000099;}
.mysql.geshifilter-mysql .kw27 {color: #00CC00;}
.mysql.geshifilter-mysql .coMULTI {color: #808000; font-style: italic;}
.mysql.geshifilter-mysql .co1 {color: #808080; font-style: italic;}
.mysql.geshifilter-mysql .co2 {color: #808080; font-style: italic;}
.mysql.geshifilter-mysql .es0 {color: #004000; font-weight: bold;}
.mysql.geshifilter-mysql .es1 {color: #008080; font-weight: bold;}
.mysql.geshifilter-mysql .br0 {color: #FF00FF;}
.mysql.geshifilter-mysql .sy1 {color: #CC0099;}
.mysql.geshifilter-mysql .sy2 {color: #000033;}
.mysql.geshifilter-mysql .st0 {color: #008000;}
.mysql.geshifilter-mysql .nu0 {color: #008080;}
.mysql.geshifilter-mysql .ln-xtra, .mysql.geshifilter-mysql li.ln-xtra, .mysql.geshifilter-mysql div.ln-xtra {background-color: #ffc;}
.mysql.geshifilter-mysql span.xtra { display:block; }

/**
 * GeSHi Dynamically Generated Stylesheet
 * --------------------------------------
 * Dynamically generated stylesheet for php
 * CSS class: geshifilter-php, CSS id:
 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
 * (http://qbnz.com/highlighter/ and http://geshi.org/)
 * --------------------------------------
 */
.php.geshifilter-php .de1, .php.geshifilter-php .de2 {font-family: monospace; font-weight: normal; font-style: normal}
.php.geshifilter-php  {font-family:monospace;}
.php.geshifilter-php .imp {font-weight: bold; color: red;}
.php.geshifilter-php li, .php.geshifilter-php .li1 {font-family: monospace; font-weight: normal;}
.php.geshifilter-php .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
.php.geshifilter-php .li2 {font-family: monospace; font-weight: bold; font-style: italic;}
.php.geshifilter-php .kw1 {color: #b1b100;}
.php.geshifilter-php .kw2 {color: #000000; font-weight: bold;}
.php.geshifilter-php .kw3 {color: #990000;}
.php.geshifilter-php .kw4 {color: #009900; font-weight: bold;}
.php.geshifilter-php .co1 {color: #666666; font-style: italic;}
.php.geshifilter-php .co2 {color: #666666; font-style: italic;}
.php.geshifilter-php .co3 {color: #0000cc; font-style: italic;}
.php.geshifilter-php .co4 {color: #009933; font-style: italic;}
.php.geshifilter-php .coMULTI {color: #666666; font-style: italic;}
.php.geshifilter-php .es0 {color: #000099; font-weight: bold;}
.php.geshifilter-php .es1 {color: #000099; font-weight: bold;}
.php.geshifilter-php .es2 {color: #660099; font-weight: bold;}
.php.geshifilter-php .es3 {color: #660099; font-weight: bold;}
.php.geshifilter-php .es4 {color: #006699; font-weight: bold;}
.php.geshifilter-php .es5 {color: #006699; font-weight: bold; font-style: italic;}
.php.geshifilter-php .es6 {color: #009933; font-weight: bold;}
.php.geshifilter-php .es_h {color: #000099; font-weight: bold;}
.php.geshifilter-php .br0 {color: #009900;}
.php.geshifilter-php .sy0 {color: #339933;}
.php.geshifilter-php .sy1 {color: #000000; font-weight: bold;}
.php.geshifilter-php .st0 {color: #0000ff;}
.php.geshifilter-php .st_h {color: #0000ff;}
.php.geshifilter-php .nu0 {color: #cc66cc;}
.php.geshifilter-php .nu8 {color: #208080;}
.php.geshifilter-php .nu12 {color: #208080;}
.php.geshifilter-php .nu19 {color:#800080;}
.php.geshifilter-php .me1 {color: #004000;}
.php.geshifilter-php .me2 {color: #004000;}
.php.geshifilter-php .re0 {color: #000088;}
.php.geshifilter-php .ln-xtra, .php.geshifilter-php li.ln-xtra, .php.geshifilter-php div.ln-xtra {background-color: #ffc;}
.php.geshifilter-php span.xtra { display:block; }

/**
 * GeSHi Dynamically Generated Stylesheet
 * --------------------------------------
 * Dynamically generated stylesheet for python
 * CSS class: geshifilter-python, CSS id:
 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
 * (http://qbnz.com/highlighter/ and http://geshi.org/)
 * --------------------------------------
 */
.python.geshifilter-python .de1, .python.geshifilter-python .de2 {font-family: monospace; font-weight: normal; font-style: normal}
.python.geshifilter-python  {font-family:monospace;}
.python.geshifilter-python .imp {font-weight: bold; color: red;}
.python.geshifilter-python li, .python.geshifilter-python .li1 {font-family: monospace; font-weight: normal;}
.python.geshifilter-python .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
.python.geshifilter-python .li2 {font-family: monospace; font-weight: bold; font-style: italic;}
.python.geshifilter-python .kw1 {color: #ff7700;font-weight:bold;}
.python.geshifilter-python .kw2 {color: #008000;}
.python.geshifilter-python .kw3 {color: #dc143c;}
.python.geshifilter-python .kw4 {color: #0000cd;}
.python.geshifilter-python .co1 {color: #808080; font-style: italic;}
.python.geshifilter-python .coMULTI {color: #808080; font-style: italic;}
.python.geshifilter-python .es0 {color: #000099; font-weight: bold;}
.python.geshifilter-python .br0 {color: black;}
.python.geshifilter-python .sy0 {color: #66cc66;}
.python.geshifilter-python .st0 {color: #483d8b;}
.python.geshifilter-python .nu0 {color: #ff4500;}
.python.geshifilter-python .me1 {color: black;}
.python.geshifilter-python .ln-xtra, .python.geshifilter-python li.ln-xtra, .python.geshifilter-python div.ln-xtra {background-color: #ffc;}
.python.geshifilter-python span.xtra { display:block; }

/**
 * GeSHi Dynamically Generated Stylesheet
 * --------------------------------------
 * Dynamically generated stylesheet for rails
 * CSS class: geshifilter-rails, CSS id:
 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
 * (http://qbnz.com/highlighter/ and http://geshi.org/)
 * --------------------------------------
 */
.rails.geshifilter-rails .de1, .rails.geshifilter-rails .de2 {font-family: monospace; font-weight: normal; font-style: normal}
.rails.geshifilter-rails  {font-family:monospace;}
.rails.geshifilter-rails .imp {font-weight: bold; color: red;}
.rails.geshifilter-rails li, .rails.geshifilter-rails .li1 {font-family: monospace; font-weight: normal;}
.rails.geshifilter-rails .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
.rails.geshifilter-rails .li2 {font-family: monospace; font-weight: bold; font-style: italic;}
.rails.geshifilter-rails .kw1 {color:#9966CC; font-weight:bold;}
.rails.geshifilter-rails .kw2 {color:#0000FF; font-weight:bold;}
.rails.geshifilter-rails .kw3 {color:#CC0066; font-weight:bold;}
.rails.geshifilter-rails .kw4 {color:#CC00FF; font-weight:bold;}
.rails.geshifilter-rails .kw5 {color:#5A0A0A; font-weight:bold;}
.rails.geshifilter-rails .co1 {color:#008000; font-style:italic;}
.rails.geshifilter-rails .coMULTI {color:#000080; font-style:italic;}
.rails.geshifilter-rails .es0 {color:#000099;}
.rails.geshifilter-rails .br0 {color:#006600; font-weight:bold;}
.rails.geshifilter-rails .sy0 {color:#006600; font-weight:bold;}
.rails.geshifilter-rails .st0 {color:#996600;}
.rails.geshifilter-rails .nu0 {color:#006666;}
.rails.geshifilter-rails .me1 {color:#9900CC;}
.rails.geshifilter-rails .re0 {color:#ff6633; font-weight:bold;}
.rails.geshifilter-rails .re1 {color:#0066ff; font-weight:bold;}
.rails.geshifilter-rails .re2 {color:#6666ff; font-weight:bold;}
.rails.geshifilter-rails .re3 {color:#ff3333; font-weight:bold;}
.rails.geshifilter-rails .ln-xtra, .rails.geshifilter-rails li.ln-xtra, .rails.geshifilter-rails div.ln-xtra {background-color: #ffc;}
.rails.geshifilter-rails span.xtra { display:block; }

/**
 * GeSHi Dynamically Generated Stylesheet
 * --------------------------------------
 * Dynamically generated stylesheet for ruby
 * CSS class: geshifilter-ruby, CSS id:
 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
 * (http://qbnz.com/highlighter/ and http://geshi.org/)
 * --------------------------------------
 */
.ruby.geshifilter-ruby .de1, .ruby.geshifilter-ruby .de2 {font-family: monospace; font-weight: normal; font-style: normal}
.ruby.geshifilter-ruby  {font-family:monospace;}
.ruby.geshifilter-ruby .imp {font-weight: bold; color: red;}
.ruby.geshifilter-ruby li, .ruby.geshifilter-ruby .li1 {font-family: monospace; font-weight: normal;}
.ruby.geshifilter-ruby .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
.ruby.geshifilter-ruby .li2 {font-family: monospace; font-weight: bold; font-style: italic;}
.ruby.geshifilter-ruby .kw1 {color:#9966CC; font-weight:bold;}
.ruby.geshifilter-ruby .kw2 {color:#0000FF; font-weight:bold;}
.ruby.geshifilter-ruby .kw3 {color:#CC0066; font-weight:bold;}
.ruby.geshifilter-ruby .kw4 {color:#CC00FF; font-weight:bold;}
.ruby.geshifilter-ruby .co1 {color:#008000; font-style:italic;}
.ruby.geshifilter-ruby .co4 {color: #cc0000; font-style: italic;}
.ruby.geshifilter-ruby .coMULTI {color:#000080; font-style:italic;}
.ruby.geshifilter-ruby .es0 {color:#000099;}
.ruby.geshifilter-ruby .br0 {color:#006600; font-weight:bold;}
.ruby.geshifilter-ruby .sy0 {color:#006600; font-weight:bold;}
.ruby.geshifilter-ruby .st0 {color:#996600;}
.ruby.geshifilter-ruby .nu0 {color:#006666;}
.ruby.geshifilter-ruby .me1 {color:#9900CC;}
.ruby.geshifilter-ruby .re0 {color:#ff6633; font-weight:bold;}
.ruby.geshifilter-ruby .re1 {color:#0066ff; font-weight:bold;}
.ruby.geshifilter-ruby .re2 {color:#6666ff; font-weight:bold;}
.ruby.geshifilter-ruby .re3 {color:#ff3333; font-weight:bold;}
.ruby.geshifilter-ruby .ln-xtra, .ruby.geshifilter-ruby li.ln-xtra, .ruby.geshifilter-ruby div.ln-xtra {background-color: #ffc;}
.ruby.geshifilter-ruby span.xtra { display:block; }

/**
 * GeSHi Dynamically Generated Stylesheet
 * --------------------------------------
 * Dynamically generated stylesheet for scala
 * CSS class: geshifilter-scala, CSS id:
 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
 * (http://qbnz.com/highlighter/ and http://geshi.org/)
 * --------------------------------------
 */
.scala.geshifilter-scala .de1, .scala.geshifilter-scala .de2 {font-family: monospace; font-weight: normal; font-style: normal}
.scala.geshifilter-scala  {font-family:monospace;}
.scala.geshifilter-scala .imp {font-weight: bold; color: red;}
.scala.geshifilter-scala li, .scala.geshifilter-scala .li1 {font-family: monospace; font-weight: normal;}
.scala.geshifilter-scala .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
.scala.geshifilter-scala .li2 {font-family: monospace; font-weight: bold; font-style: italic;}
.scala.geshifilter-scala .kw1 {color: #0000ff; font-weight: bold;}
.scala.geshifilter-scala .kw2 {color: #9999cc; font-weight: bold;}
.scala.geshifilter-scala .co1 {color: #008000; font-style: italic;}
.scala.geshifilter-scala .co2 {color: #CC66FF;}
.scala.geshifilter-scala .coMULTI {color: #00ff00; font-style: italic;}
.scala.geshifilter-scala .es0 {color: #6666ff; font-weight: bold;}
.scala.geshifilter-scala .es1 {color: #6666ff; font-weight: bold;}
.scala.geshifilter-scala .es2 {color: #5555ff; font-weight: bold;}
.scala.geshifilter-scala .es3 {color: #4444ff; font-weight: bold;}
.scala.geshifilter-scala .es4 {color: #3333ff; font-weight: bold;}
.scala.geshifilter-scala .br0 {color: #F78811;}
.scala.geshifilter-scala .sy0 {color: #000080;}
.scala.geshifilter-scala .st0 {color: #6666FF;}
.scala.geshifilter-scala .nu0 {color: #F78811;}
.scala.geshifilter-scala .me1 {color: #000000;}
.scala.geshifilter-scala .me2 {color: #000000;}
.scala.geshifilter-scala .ln-xtra, .scala.geshifilter-scala li.ln-xtra, .scala.geshifilter-scala div.ln-xtra {background-color: #ffc;}
.scala.geshifilter-scala span.xtra { display:block; }

/**
 * GeSHi Dynamically Generated Stylesheet
 * --------------------------------------
 * Dynamically generated stylesheet for sql
 * CSS class: geshifilter-sql, CSS id:
 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
 * (http://qbnz.com/highlighter/ and http://geshi.org/)
 * --------------------------------------
 */
.sql.geshifilter-sql .de1, .sql.geshifilter-sql .de2 {font-family: monospace; font-weight: normal; font-style: normal}
.sql.geshifilter-sql  {font-family:monospace;}
.sql.geshifilter-sql .imp {font-weight: bold; color: red;}
.sql.geshifilter-sql li, .sql.geshifilter-sql .li1 {font-family: monospace; font-weight: normal;}
.sql.geshifilter-sql .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
.sql.geshifilter-sql .li2 {font-family: monospace; font-weight: bold; font-style: italic;}
.sql.geshifilter-sql .kw1 {color: #993333; font-weight: bold;}
.sql.geshifilter-sql .co1 {color: #808080; font-style: italic;}
.sql.geshifilter-sql .coMULTI {color: #808080; font-style: italic;}
.sql.geshifilter-sql .es0 {color: #000099; font-weight: bold;}
.sql.geshifilter-sql .br0 {color: #66cc66;}
.sql.geshifilter-sql .sy0 {color: #66cc66;}
.sql.geshifilter-sql .st0 {color: #ff0000;}
.sql.geshifilter-sql .nu0 {color: #cc66cc;}
.sql.geshifilter-sql .ln-xtra, .sql.geshifilter-sql li.ln-xtra, .sql.geshifilter-sql div.ln-xtra {background-color: #ffc;}
.sql.geshifilter-sql span.xtra { display:block; }

/**
 * GeSHi Dynamically Generated Stylesheet
 * --------------------------------------
 * Dynamically generated stylesheet for text
 * CSS class: geshifilter-text, CSS id:
 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
 * (http://qbnz.com/highlighter/ and http://geshi.org/)
 * --------------------------------------
 */
.text.geshifilter-text .de1, .text.geshifilter-text .de2 {font-family: monospace; font-weight: normal; font-style: normal}
.text.geshifilter-text  {font-family:monospace;}
.text.geshifilter-text .imp {font-weight: bold; color: red;}
.text.geshifilter-text li, .text.geshifilter-text .li1 {font-family: monospace; font-weight: normal;}
.text.geshifilter-text .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
.text.geshifilter-text .li2 {font-family: monospace; font-weight: bold; font-style: italic;}
.text.geshifilter-text .ln-xtra, .text.geshifilter-text li.ln-xtra, .text.geshifilter-text div.ln-xtra {background-color: #ffc;}
.text.geshifilter-text span.xtra { display:block; }

/**
 * GeSHi Dynamically Generated Stylesheet
 * --------------------------------------
 * Dynamically generated stylesheet for xml
 * CSS class: geshifilter-xml, CSS id:
 * GeSHi (C) 2004 - 2007 Nigel McNie, 2007 - 2008 Benny Baumann
 * (http://qbnz.com/highlighter/ and http://geshi.org/)
 * --------------------------------------
 */
.xml.geshifilter-xml .de1, .xml.geshifilter-xml .de2 {font-family: monospace; font-weight: normal; font-style: normal}
.xml.geshifilter-xml  {font-family:monospace;}
.xml.geshifilter-xml .imp {font-weight: bold; color: red;}
.xml.geshifilter-xml li, .xml.geshifilter-xml .li1 {font-family: monospace; font-weight: normal;}
.xml.geshifilter-xml .ln {width:1px;text-align:right;margin:0;padding:0 2px;vertical-align:top;}
.xml.geshifilter-xml .li2 {font-family: monospace; font-weight: bold; font-style: italic;}
.xml.geshifilter-xml .es0 {color: #000099; font-weight: bold;}
.xml.geshifilter-xml .br0 {color: #66cc66;}
.xml.geshifilter-xml .sy0 {color: #66cc66;}
.xml.geshifilter-xml .st0 {color: #ff0000;}
.xml.geshifilter-xml .nu0 {color: #cc66cc;}
.xml.geshifilter-xml .sc-1 {color: #808080; font-style: italic;}
.xml.geshifilter-xml .sc0 {color: #00bbdd;}
.xml.geshifilter-xml .sc1 {color: #ddbb00;}
.xml.geshifilter-xml .sc2 {color: #339933;}
.xml.geshifilter-xml .sc3 {color: #009900;}
.xml.geshifilter-xml .re0 {color: #000066;}
.xml.geshifilter-xml .re1 {color: #000000; font-weight: bold;}
.xml.geshifilter-xml .re2 {color: #000000; font-weight: bold;}
.xml.geshifilter-xml .ln-xtra, .xml.geshifilter-xml li.ln-xtra, .xml.geshifilter-xml div.ln-xtra {background-color: #ffc;}
.xml.geshifilter-xml span.xtra { display:block; }
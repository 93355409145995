.page-error{

  background-color:$colour-primary;
  color:$light;

  a{
   color:$light;
  }

  .layout-header,
  .footer-base .copyright{
    background-color:$colour-primary;
    color:$light;
  }

  .layout-branding .site-name a,
  .footer-base .signature,
  .footer-base .signature a,
  .footer-base .signature a:hover{
    background-color:transparent;
    color:$light;
  }

  .footer-base .signature a:hover{
    text-decoration:underline;
  }

  .container--menu-toggle .menu-toggle .menu-toggle__hamburger,
  .container--menu-toggle .menu-toggle .menu-toggle__hamburger:after,
  .container--menu-toggle .menu-toggle .menu-toggle__hamburger:before{
    background-color:$light;
  }

  .container--menu-toggle .menu-toggle:hover .menu-toggle__hamburger,
  .container--menu-toggle .menu-toggle:hover .menu-toggle__hamburger:after,
  .container--menu-toggle .menu-toggle:hover .menu-toggle__hamburger:before{
    background-color:$dark;
  }

  .main-navigation .l-region--navigation .menu li a{
    color:$link-primary;
  }

  .main-navigation .l-region--navigation .menu li a:hover{
    color:$colour-secondary;
    border-bottom-color:$link-primary;
  }

  .content-header .page-title .page-title__title{
    background-color:transparent;
    @include rem-attribute('min-height',22);
  }

}
.js-carousel-pager-header-wrapper{
  @include rem-attribute('margin-top', 5);
  @include rem-attribute('padding-bottom', 1);

  .background{
    opacity:0;
    position: absolute;
    @include alpha-attribute('background-color', rgba($light, 0.75), $light);
    width:100%;
    @include rem-attribute('height',3.1);
    transition:opacity 0.3s ease;
  }

  .js-carousel-pager-container{
    position: relative;
    z-index: 100;

    @include span(22 of 24);
    margin-left:auto;
    margin-right:auto;
    float:none;
    overflow:auto;
  }

  li{
    a{
      color:$grey;
      display:block;
      @include rem-attribute('padding-top', 1);
      &:hover{
        color:$link-primary;
        text-decoration:none;
      }
      .fa-angle-left{
        //float:left;
      }
      .fa-angle-right{
        //float:right;
      }
      .text{
        display:inline-block;
        opacity:0;
        transition:opacity 0.4s ease-out, max-width 0.3s ease;

        overflow:hidden;
        white-space:nowrap;
        word-wrap:normal;
        text-overflow:ellipsis;
        max-width:82%;
      }
    }
    &.active{
      min-width:90%;
      max-width:90%;
    }
    &.non-active{
      max-width:9%;
    }
    &.next{
      text-align:right;
    }
  }

  &:hover{
    @include alpha-attribute('background-color', rgba($light, 0.4), $light);
    .background{
      opacity:1;
    }
    a{
      color:$grey-dark;
      .text{
        opacity:1;
        &.hide{
          max-width:0;
          opacity:0;
        }
      }
      &:hover{
        color:$link-primary;
      }
    }
  }

   //Prevent container becoming shorter than the content container
  @include breakpoint ($breakpoint-small){
    min-width:$container-small;
    li{
      a{
        .text{
          max-width:92%;
        }
      }
    }
  }
}

.featured{
  .js-carousel-pager-header-wrapper{
    @include rem-attribute('padding-bottom', 2);
    a{
      @include rem-attribute('padding-bottom', 1);
    }
  }
}
.front{
  &.featured .content-header{
      @include rem-attribute('margin-bottom', 1);
    .content-header__content{
      @include rem-attribute('bottom', 1);
    }
    .block--views-featured-image-block{
      padding-bottom:0;
    }
    .page-title{
      text-align:center;
      margin:auto;
      float:none;
      .page-title__title{
        @include rem-attribute('font-size', 3.5);
        padding-right:0;
        padding-top:0;
        color:$light;
        background-color:transparent;
        text-align:center;
        font-weight:400;
        line-height:1.3;
      }
      .typed-cursor{
        @include rem-attribute('font-size', 3.5);
        color:$light;

        width:1px;
        display:inline-block;
      }
    }
  }

  .block--views-blog-listing-recent,
  .view-blog-listing .teaser-listing{
    padding-bottom:0;
  }

  @include breakpoint($breakpoint-small){
    &.featured .content-header{
      .content-header__content{
        @include rem-attribute('bottom', 3);
      }
    }
  }

  @include breakpoint($breakpoint-medium){
    &.featured .content-header{
      @include rem-attribute('margin-bottom', 2);
      .content-header__content{
        bottom:auto;
        top:50%;
      }
      .page-title .page-title__title,
      .page-title .typed-cursor{
        @include rem-attribute('font-size',5);
        line-height:1.3;
      }
    }
  }

  @include breakpoint($breakpoint-large){
    &.featured .content-header{
      .page-title .page-title__title,
      .page-title .typed-cursor{
        @include rem-attribute('font-size',6);
      }
    }
  }
}

.js{
  .front{
    &.featured .content-header{
      .page-title{
        overflow:hidden;
        .page-title__title-untyped{
          display:none;
        }
      }
    }
  }
}
.ajax-loader-notice{
  position:fixed;
  top:0;
  left:0;
  height:100%;
  width:100%;
  text-align:center;
  background-color:$light;
  .ajax-loader-notice__content{
    position:absolute;
    top:0; right:0; bottom:0; left:0;
    margin:auto;
    @include rem-attribute('height',3.5);
  }
  .logo{
    font-family:$serif;
    @include rem-attribute('font-size',1.25);
    color:$grey-dark;
    font-weight:$normalweight;
    span + span{
      font-weight:$lightweight;
    }
    -webkit-animation:rotate 1.5s ease infinite;
    -moz-animation:rotate 1.5s ease infinite;
    animation:rotate 1.5s ease infinite;
  }
  .loading{
    font-family:$serif;
    color:$grey-dark;
    text-align:center;
    span{
      opacity:0;
      @include rem-attribute('font-size', 1.5);

      -webkit-animation:reveal 1s linear infinite;
      -moz-animation:reveal 1s linear infinite;
      animation:reveal 1s linear infinite;

    }
    span + span{
      animation-delay:0.2s;
    }
    span + span + span{
      animation-delay:0.4s;
    }
    span + span + span + span{
      animation-delay:0.6s;
    }
    span + span + span + span + span{
      animation-delay:0.8s;
    }
  }
}

@-moz-keyframes rotate {
    0% { -moz-transform: rotateY(0deg); }
    75% { -moz-transform: rotateY(360deg); }
    100% { -moz-transform: rotateY(360deg); }
}
@-webkit-keyframes rotate {
    0% { -webkit-transform: rotateY(0deg); }
    75% { -webkit-transform: rotateY(360deg); }
    100% { -webkit-transform: rotateY(360deg); }
}
@keyframes rotate {
    0% { transform: rotateY(0deg); }
    75% { transform: rotateY(360deg); }
    100% { transform: rotateY(360deg); }
}

@-moz-keyframes reveal {
  0% { opacity:0; }
  33% { opacity:1; }
  66% { opacity:1; }
  100% { opacity:0; }
}
@-webkit-keyframes reveal {
  0% { opacity:0; }
  33% { opacity:1; }
  66% { opacity:1; }
  100% { opacity:0; }
}
@keyframes reveal {
  0% { opacity:0; }
  33% { opacity:1; }
  66% { opacity:1; }
  100% { opacity:0; }
}
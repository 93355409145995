// Grid
.layout-tab{
  @include span(22 of 24);
  @include push(1 of 24);
  clear:both;

  position:relative;
  z-index:10;
}

@media (max-width:$breakpoint-small){
  .layout-tab{
    .block{
      max-height:none !important;
      min-height:0 !important;
    }
  }
}

@include breakpoint($breakpoint-small){
  .layout-tab{
    margin-left:auto;
    margin-right:auto;
    float:none;
  }
}

// General
.layout-tab{
  background-color:$light;
  .block{
    .block__title{
      margin-top:0;
      @include rem-attribute('font-size', 1.25);
      text-transform:none;
    }
    .block__content{
      p{
        margin-top:0;
        &:last-child{
          margin-bottom:0;
        }
      }
    }
    &:first-child{
      @include rem-attribute('padding-top', 1);
      border-top:1px solid $grey-light;
    }
  }
}

// Tabs
@include breakpoint ($breakpoint-small){
html.js .layout-tab{
  position:fixed;
  z-index:50;
  top:16em;
  width:25%;
  right:-25%;
  border-top:none;
  .block{
    position:relative;
    right:0;
    padding-top:0;
    padding-bottom:0;

    // use 'max-height' instead of 'height' because
    // CSS transitions don't work on 'height with a value
    // of 'auto' or a percentage
    max-height:0;
    transition: max-height 600ms ease-out, right 400ms ease-out;
    background-color:$light;
    border-left:1px solid $grey;
    border-top:1px solid $grey;
    border-bottom:1px solid $grey;
    @include rem-attribute('margin-bottom', 1);

    .block__title{
      position:absolute;
      top:-1px;
      margin:0;
      display:inline-block;
      transform: rotate(90deg);
      transform-origin: left top 0;
      transition: color 0.2s linear, background-color 0.2s linear;

      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;

      border:1px solid $grey;
      border-top:none;

      font-weight:$lightweight;
      @include rem-attribute('font-size',1);
      @include rem-attribute('padding',0.2);
      @include rem-attribute('padding-left',0.5);
      @include rem-attribute('padding-right',0.5);

      cursor:pointer;
      background-color:$light;
      white-space:nowrap;
      &:hover{
        color:$light;
        background-color:$colour-primary;
      }
    }
    .block__content{
      overflow:hidden;
      @include rem-attribute('padding',0.5);
    }
    &.is-active{
      right:100%;
      max-height:1000px !important;
    }
  }
}
}

/* @include breakpoint ($breakpoint-medium){
  html.js .layout-tab{
    .block{
      .block__title{
        @include rem-attribute('font-size', 1.5);
      }
    }
  }
} */


.button{
  border:none;
  border-radius:3px;
  font:$font-paragraph;
  line-height:2;
  font-weight:300;
  @include rem-attribute('padding-left', 1);
  @include rem-attribute('padding-right', 1);
  cursor:pointer;
  width:100%;
}

.button-primary{
  color:$light;
  @include button-bg($colour-primary);
}

.button-secondary{
  color:$light;
  @include button-bg($colour-secondary);
}

@include breakpoint($breakpoint-small){
  .button{
    width:auto;
  }
}
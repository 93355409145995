.glitch{

  ::selection{
    background-color:$grey-lighter;
    color:$dark;
  }

  background-color:$grey-darker;
  color:$green;

  a{
   color:$green;
   &:hover{
    text-decoration:underline !important;
   }
  }

  .layout-header,
  .layout-branding .site-name a,
  .footer-base .copyright,
  .footer-base .signature,
  .footer-base .contact,
  .footer-base .signature a,
  .footer-base .signature a:hover{
    background-color:transparent;
    color:$green;
  }

  .layout-header{
    border-bottom-color:$green;
    background-color:$dark;

  }

  .layout-footer__inner{
    border-top-color:$green;
  }

  .footer-base .signature a:hover{
    text-decoration:underline;
  }

  .container--menu-toggle .menu-toggle .menu-toggle__hamburger,
  .container--menu-toggle .menu-toggle .menu-toggle__hamburger:after,
  .container--menu-toggle .menu-toggle .menu-toggle__hamburger:before{
    background-color:$green;
  }

  .container--menu-toggle .menu-toggle:hover .menu-toggle__hamburger,
  .container--menu-toggle .menu-toggle:hover .menu-toggle__hamburger:after,
  .container--menu-toggle .menu-toggle:hover .menu-toggle__hamburger:before{
    background-color:$colour-secondary;
  }

  .main-navigation{
    background-color:rgba($grey-darker,0.90);
  }

  .main-navigation .l-region--navigation .menu li a{
    color:$green;
  }

  .main-navigation .l-region--navigation .menu li a:hover{
    border-bottom-color:$green;
    text-decoration:none !important;
  }

  .content-header .page-title .page-title__title{
    color:$green !important;
    background-color:transparent;
  }

  .typed-cursor{
    color:$green !important;
  }

  // Glitch text
  .page-title__title-typed-wrapper{
    // position:relative;
    // display:block;
  }

  .glitch-text{
    position: relative;
    display:inline-block;
  }

  .glitch-text::before,
  .glitch-text::after {
    content: attr(data-text);
    position: absolute;
    // top: -20px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch-text::before {
    left: 2px;
    text-shadow: 1px 0 red;
    background: inherit;
    animation: noise-anim-1 5s infinite linear alternate-reverse;
  }
  .glitch-text::after {
    left: -2px;
    text-shadow: -1px 0 blue;
    background: inherit;
    animation: noise-anim-2 5s infinite linear alternate-reverse;
  }

  @keyframes noise-anim-1{
    $steps: 10;
    @for $i from 0 through $steps {
      $random: random(100);
      #{percentage($i*(1/$steps))} {
        clip-path: inset( $random+% 0 random((101-$random))+% 0);
      }
    }
  }

  @keyframes noise-anim-2{
    $steps: 10;
    @for $i from 0 through $steps {
      $random: random(100);
      #{percentage($i*(1/$steps))} {
        clip-path: inset( $random+% 0 random((101-$random))+% 0);
      }
    }
  }
}




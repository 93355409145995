@keyframes horizontal-bounce {
  11%{margin-left:0;}
  22%{margin-left:-8px;}
  33%{margin-left:0;}
  44%{margin-left:8px;}
  55%{margin-left:0;}
  66%{margin-left:-8px;}
  77%{margin-left:0;}
  88%{margin-left:8px;}
  100%{margin-left:0;}
}

@-webkit-keyframes horizontal-bounce {
  11%{margin-left:0;}
  22%{margin-left:-8px;}
  33%{margin-left:0;}
  44%{margin-left:8px;}
  55%{margin-left:0;}
  66%{margin-left:-8px;}
  77%{margin-left:0;}
  88%{margin-left:8px;}
  100%{margin-left:0;}
}

@-moz-keyframes horizontal-bounce {
  11%{margin-left:0;}
  22%{margin-left:-8px;}
  33%{margin-left:0;}
  44%{margin-left:8px;}
  55%{margin-left:0;}
  66%{margin-left:-8px;}
  77%{margin-left:0;}
  88%{margin-left:8px;}
  100%{margin-left:0;}
}

@-o-keyframes horizontal-bounce {
  11%{margin-left:0;}
  22%{margin-left:-8px;}
  33%{margin-left:0;}
  44%{margin-left:8px;}
  55%{margin-left:0;}
  66%{margin-left:-8px;}
  77%{margin-left:0;}
  88%{margin-left:8px;}
  100%{margin-left:0;}
}
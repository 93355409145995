.layout-main-content{
  @include rem-attribute('padding-bottom', 1);
  .submitted{
    color:$grey-dark;
    @include rem-attribute('font-size', 0.75);
    @include rem-attribute('margin-top', 0.5);
  }
  .statistics_counter{
    display:none;
  }
}
.main-navigation{
  position:fixed;
  z-index:75;
  padding:0;
  margin:0;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background-color:rgba($light,0.90);

  margin-left:200%;
  left:-100%;
  transition: left 500ms linear, margin-left 600ms ease-in-out;

  &.is-active{
    margin-left:0;
    left:0;
    transition: left 400ms ease-out;
  }


  .l-region--navigation{
    .menu{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      li{
        list-style:none;
        text-align:center;

        font:$font-heading;
        @include rem-attribute('font-size',3);
        line-height:1.5;
        a{
          text-decoration:none;
          border-bottom: 1px solid transparent;
          &:hover{
            border-bottom: 1px solid $colour-primary;
          }
        }
      }
    }
  }
}
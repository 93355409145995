.node{
  pre{
    padding:0;
  }
  code{
    display:inline;
    font:$font-mono;
    @include rem-attribute('padding-top',0.125);
    @include rem-attribute('padding-bottom',0.125);
  }
  code.nohighlight,
  code.text{
    background-color:$grey-lightest;
    color:$colour-primary;
    @include rem-attribute('padding-left',0.25);
    @include rem-attribute('padding-right',0.25);
  }
  code.nohighlight.nobg,
  code.text.nobg{
    background-color:transparent;
  }
  pre > code{
    display:block;
    line-height:1.375;
    padding:16px !important;
    padding:1rem !important;
    @include rem-attribute('margin-top',1);
    //@include rem-attribute('margin-bottom',1);
    font:$font-mono-block;
  }
}

.nohighlight.dark,
.text.dark{
  background-color:$code-background;
  color:$light;
  @include rem-attribute('padding-left',0.3);
  @include rem-attribute('padding-right',0.3);
}
.view-sandbox-categories-listing.view-display-id-block{
  @include rem-attribute('margin-top',0.75);
    .fa-ul{
      margin-left:0;
      .fa{
        @include rem-attribute('font-size',0.5);
        @include rem-attribute('padding-top',0.3);
      }
    }
    li{
      list-style:none;
      display:inline-block;
      @include rem-attribute('margin-left',1);
      @include rem-attribute('margin-right',2);
      a{
        @include rem-attribute('font-size',0.75);
    }
  }
}

.js-carousel-pager-wrapper{
  z-index:100;
  position:relative;
  overflow:auto;
  .js-carousel-pager{
    li{
      margin-left:0;
      list-style-type: none;
      transition:max-width 0.3s ease, min-width 0.3s ease;
      max-width:48%;
      min-width:0;
      a{
        outline:none;
      }
    }
    .prev{
      float:left;
    }
    .next{
      float:right;
    }
  }
}

.featured{
  .js-carousel-pager-wrapper{

  }
}
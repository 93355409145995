.featured{
  .content-header{
    @include rem-attribute('min-height', 20);
    .page-title{
        @include span(24 of 24);
        @include push(0 of 24);
      .page-title__title{
        //display: inline-block;
        @include rem-attribute('padding-top', 1);
        @include rem-attribute('padding-left', 1);
      }
    }
  }
}

.content-header{
  position:relative;
  overflow:hidden;
  @include rem-attribute('min-height', 12);
  .l-region--content-header{
    position:absolute;
    height:100%;
    top:0;
    left:0;
    .block{
      padding-top:0;
    }
    div{
      height:100%;
    }
    img{
      min-width:100%;
      min-height:100%;
      max-width:none;

      position: relative;
      //top:-50px;
    }
  }

  .l-region--content-header{
    @include span(12);
  }

  .content-header__content{
    position:absolute;
    bottom:0;
    width:100%;
  }

  .page-title{
    position:relative;
    z-index:50;

    @include span(22 of 24);
    @include push(1 of 24);
    clear:both;
    overflow:auto;

    //@include rem-attribute('margin-top',5);

    .page-title__title{
      @include alpha-attribute('background-color', rgba($light, 0.75), $light);
      @include rem-attribute('padding-right', 1);
      @include rem-attribute('padding-bottom', 1);
      padding-top:0;
      //@include rem-attribute('padding-left', 1.5);
      line-height:1.5;
      margin-bottom:0;
      margin-top:0;
    }
  }

}

@include breakpoint($breakpoint-small){
  .featured{
    .content-header{
      .page-title{
        @include span(22 of 24);
        @include push(1 of 24);
      }
    }
  }
  .content-header{
    .page-title{
      @include span(22 of 24);
      @include push(1 of 24);
    }
  }
}

@include breakpoint($breakpoint-medium){
  .featured{
    .content-header{
      @include rem-attribute('min-height', 22);
    }
  }
}

@include breakpoint($breakpoint-large){
  .featured{
    .content-header{
      @include rem-attribute('min-height', 25);
    }
  }
}
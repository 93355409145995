.section-pattern-library{
  section{
    clear:both;
    overflow:auto;
    @include rem-attribute('margin-bottom',1);
    @include rem-attribute('padding-bottom',2);
    border-bottom:1px solid $dark;
  }

  .title{
    margin-top:0;
  }

  h3.title{
    @include rem-attribute('padding-top',1);
    text-transform:uppercase;
    border-top:1px dashed $grey-light;
  }

  code.nohighlight{
    background-color:transparent;
    color:$dark;
  }

  .dark code.nohighlight,
  .grey-dark code.nohighlight,
  .grey-darker code.nohighlight{
    color:$light;
  }
}
.node-type-blog-post{

  .main-carousel{
    transition:opacity 0.75s ease-out;
    opacity:1;
    .slide{
      transition:opacity 0.3s ease-in;
      opacity:1;
      background-color:$light;
    }
    .js-ajax-loading{
      opacity:0;
    }
    .js-ajax-loaded{
    }

    .js-node-navigation{
      .slick-arrow.slick-hidden{
        //display:block;
      }
    }
  }

  .main-carousel-wrapper.js-carousel-loading .main-carousel,
  .main-carousel-wrapper.js-ajax-loading .main-carousel{
    transition:opacity 0.25s ease-out;
    opacity:0;
  }

}
.view-blog-listing{
  &.view-display-id-page_1 li{
    @include rem-attribute('margin-bottom',1);
      font:$font-heading;
      @include rem-attribute('font-size', 1.5);
    a{
      font:$font-heading;
      @include rem-attribute('font-size', 1.5);
      @include rem-attribute('margin-right',0.25);
    }
  }
  &.view-display-id-page_1 .view-content,
  &.view-display-id-page_2 .view-content,
  &.view-display-id-page_blog_complete .view-content{
    @include rem-attribute('min-height', 30);
  }
  .teaser-listing{
      //border-bottom:1px solid $grey-light;
      @include rem-attribute('padding-bottom', 1);
      @include rem-attribute('margin-bottom', 1);
    .teaser-header{
      position:relative;
      a{
        .date{
          float:left;
          color:$dark;
        }
        .readmore{
          float:right;
          display:none;
        }
      }
      &.featured-container{
        a{
          .featured-image{
          img{
            display:block;
          }
        }
        .date, .readmore{
          position:absolute;
          bottom:0;
          @include rem-attribute('padding', 0.5);
          @include alpha-attribute('background-color', rgba($light, 0.75), $light);
        }
        .date{
          left:0;
          color:$dark;
        }
        .readmore{
          right:0;
          display:block;
        }
      }
      }
    }
    .teaser-footer{
      @include rem-attribute('padding-top', 0.5);
      overflow:auto;
      clear:both;
      h2{
        @include span(5);
        margin-bottom:0;
        margin-top:0;
        font-family:$serif;
        font-weight:$lightweight;
        @include rem-attribute('font-size', 1.25);
        a:hover{
          text-decoration:none;
        }
      }
      .snippet{
        @include span(last 7);
        overflow:hidden;
        p.lede{
        @include rem-attribute('font-size', 1);
        font-weight:$normalweight;
        }
        p:first-child{
          margin-top:0;
        }
        p:last-child{
          margin-bottom:0;
        }
      }
    }
  }
}
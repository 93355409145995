.layout-header{
  position:fixed;
  z-index:100;

  top:0;
  padding-top:1em;
  padding-bottom:1em;
  @include alpha-attribute('background-color', rgba($light, 0.95), $light);
  border-bottom:1px solid $grey-light;
  color:$grey-dark;
  transition: top 300ms ease-in;
  &.is-hidden{
    top:-100px;
  }
}

.layout-header__inner{
  @include span(22 of 24);
  margin:auto;
  float:none;

  //Prevent container becoming shorter than the content container
  @include breakpoint ($breakpoint-small){
    min-width:$container-small;
  }
  @include breakpoint ($breakpoint-medium){
    min-width:$container-medium;
  }
  @include breakpoint ($breakpoint-large){
    min-width:$container-large;
  }
}

.container--menu-toggle{
  //position:relative;
  float:right;
  width:25px;
  height:21px;

  .menu-toggle{
    position:absolute;
    top: 37%;

    display:block;
    cursor:pointer;
    width:25px;

    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;

     &:hover{
      animation:horizontal-bounce 500ms 1 linear;
      -webkit-animation:horizontal-bounce 500ms 1 linear;
      -moz-animation:horizontal-bounce 500ms 1 linear;
      animation-delay: 250ms;
      -webkit-animation-delay: 250ms;
      -moz-animation-delay: 250ms;

      .menu-toggle__hamburger,
      .menu-toggle__hamburger::before,
      .menu-toggle__hamburger::after{
        background-color:$dark;
      }

    }

    .description{
      font-family:blank;
      opacity:0;
    }

    .menu-toggle__hamburger,
    .menu-toggle__hamburger::before,
    .menu-toggle__hamburger::after{
      border-radius:10px;
      height:2px;
      width:100%;
      background-color:$grey-dark;
      position:absolute;
      content: '';
      transition: background-color 400ms linear, transform 400ms linear, margin 300ms ease-out;
    }

    .menu-toggle__hamburger{
      top:9px;
    }

    .menu-toggle__hamburger::before{
      top: -7px;
    }

    .menu-toggle__hamburger::after{
      bottom: -7px;
    }

    &:focus,&:active{
      animation:none;
      -webkit-animation:none;
      -moz-animation:none;
    }
  }
}

// Menu state
#menu-state{
  display:none;
}

#menu-state:checked + label{
  // Burger
  & .menu-toggle__hamburger{
    background-color:transparent;
    margin-left:100px;
  }
  // Buns
  & .menu-toggle__hamburger::before,
  & .menu-toggle__hamburger::after{

  }
  // Top Bun
  & .menu-toggle__hamburger::before{
    transform: rotate(315deg);
    top:0;
    margin-left:-100px;
  }
  // Bottom Bun
  & .menu-toggle__hamburger::after{
    transform: rotate(-315deg);
    bottom:0;
    margin-left:-100px;
  }

  &:hover{
    animation:none;
    -webkit-animation:none;
    -moz-animation:none;
  }
}

.block{
  @include rem-attribute('padding-bottom', 1);
  .block__title{
    font-family:$serif;
    font-weight:$lightweight;
    @include rem-attribute('font-size', 1.25);
    margin-top:0;
    text-transform:none;
  }
  @include breakpoint($breakpoint-medium){
    @include rem-attribute('padding-bottom', 1.5);
  }
}
a{
  text-decoration:none;
  transition: all 400ms linear;
  &:link, &:visited, &:hover, &:active{
    color:$colour-primary;
  }
  &:hover{
  text-decoration:underline;
  color:$colour-secondary;
  }
}

.more-link{
  @include rem-attribute('margin-top', 0.5);
  text-align:left;
}
$dark: #222;
$light: #fff;
$grey:#aaa;
$grey-light:#ddd;
$grey-lighter:#e2e2e2;
$grey-lightest:#ececec;
$grey-dark:#4B4B4B;
$grey-darker:#1B1B1B;
$green:#00FF00;
$green-dark:#00CD00;

$colour-primary: #00ACCC;
$colour-secondary: #5738c4;

$colour-primary-dark: #0395A3;

$link-primary: #00ACCC;

$colour-info: $colour-primary-dark;
$colour-error: #BE1414;
$colour-success:#00A924;

$code-background:#23241f;
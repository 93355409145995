.content-subheader{
  overflow:auto;
  width:100%;
  background-color:$grey-lighter;
  color:$grey-dark;
  .l-region--content-subheader{
    @include span(22 of 24);
    @include push(1 of 24);
    clear:both;
    overflow:auto;
  }
  @include breakpoint($breakpoint-small){
    .l-region--content-subheader{
      margin-left:auto;
      margin-right:auto;
      float:none;
      .block{
        padding-top:0;
        padding-bottom:0;
        .block__title{
          @include rem-attribute('padding-top',0.5);
        }
      }
    }
  }
}
.front.glitch{
  // Glitch text
  .page-title__title-typed-wrapper{
    position:relative;
    display:block;
  }

  .glitch-text{
    position:static;
    display:inline;
  }
}
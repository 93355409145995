$sans: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$serif: 'Roboto Slab', 'Merriweather', Cambria, Baskerville, Georgia, serif;
$mono: 'Source Code Pro', 'Courier New', monospace;

$thinweight: 100;
$lightweight: 300;
$normalweight: 400;
$boldweight: 700;
$extraboldweight: 900;

$font-paragraph: normal 1rem/1.375 $sans;
$font-paragraph-italic: italic 1rem/1.375 $sans;
$font-paragraph-bold: normal 700 1rem/1.375 $sans;
$font-paragraph-light: normal 300 1rem/1.375 $sans;

$font-heading: 300 2rem/1.375 $serif;
$font-heading-bold: 900 2rem/1.375 $serif;

$font-mono: normal 1rem/1.375 $mono;
$font-mono-block: normal 0.875rem/1.375 $mono;